import "@hotwired/turbo-rails"
import { Application } from "@hotwired/stimulus"

import FeaturesTabController from './controllers/features_tab_controller'
import ScrollToController from './controllers/scroll_to_controller'
// Create a new Stimulus application instance
const application = Application.start()

// Register the controller
application.register('features-tab', FeaturesTabController)
application.register('scroll-to', ScrollToController)