import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Initialize first tab
    this.showTab('#feature-01')

    // Add click handlers to all tab buttons
    const tabButtons = this.element.querySelectorAll('[data-hs-tab]')
    tabButtons.forEach(button => {
      button.addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()
        const targetId = button.getAttribute('data-hs-tab')
        this.showTab(targetId)
      })
    })
  }

  showTab(targetId) {
    // Hide all tabs
    const allTabs = this.element.querySelectorAll('[role="tabpanel"]')
    allTabs.forEach(tab => {
      tab.hidden = true
      tab.style.display = 'none'
    })

    // Show selected tab
    const selectedTab = this.element.querySelector(targetId)
    console.log('selectedTab', selectedTab)
    if (selectedTab) {
      selectedTab.hidden = false
      selectedTab.style.display = 'block'
    }

    // Update tab button states
    const allButtons = this.element.querySelectorAll('[role="tab"]')
    allButtons.forEach(button => {
      button.setAttribute('aria-selected', 'false')
      button.parentElement.classList.remove('bg-white', 'lg:bg-white/10', 'lg:ring-1', 'lg:ring-inset', 'lg:ring-white/10')
      button.parentElement.classList.add('hover:bg-white/10', 'lg:hover:bg-white/5')

      // Reset button text color
      button.classList.remove('text-blue-600')
      button.classList.add('text-blue-100', 'hover:text-white')
    })

    const selectedButton = this.element.querySelector(`[data-hs-tab="${targetId}"]`).querySelector('[role="tab"]')
    selectedButton.setAttribute('aria-selected', 'true')
    selectedButton.parentElement.classList.remove('hover:bg-white/10', 'lg:hover:bg-white/5')
    selectedButton.parentElement.classList.add('bg-white', 'lg:bg-white/10', 'lg:ring-1', 'lg:ring-inset', 'lg:ring-white/10')

    // Update selected button text color
    selectedButton.classList.remove('text-blue-100', 'hover:text-white')
    selectedButton.classList.add('text-blue-600', 'lg:text-white')
  }
} 