import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    offset: { type: Number, default: 80 }
  }

  scrollToSection(event) {
    event.preventDefault()
    const targetId = event.currentTarget.getAttribute('data-scroll-to-target')
    const targetElement = document.querySelector(targetId)
    
    if (targetElement) {
      const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - this.offsetValue
      
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth'
      })
    }
  }
}